import { SupportedLanguages } from "@ea/shared_types/next/ea.enums";
import { getRequestConfig } from "next-intl/server";

export const locales = [SupportedLanguages.EN.toLowerCase(), SupportedLanguages.PL.toLowerCase()];

export const localePrefix = "always";

export default getRequestConfig(async ({ locale }) => ({
  messages: (
    await (locale === SupportedLanguages.EN.toLowerCase()
      ? // When using Turbopack, this will enable HMR for `en`
        import("../messages/en.json")
      : import(`../messages/${locale}.json`))
  ).default,
}));
