import { useMemo } from "react";
import { Renderable, Toast, ToastOptions, ValueOrFunction, toast as notify } from "react-hot-toast";

type Message = ValueOrFunction<Renderable, Toast>;
type ToastHandler = (message: Message, options?: ToastOptions) => string;

export const useNotification = () => {
  const createHandler =
    // eslint-disable-next-line
    (method: ToastHandler) => (msg: Message, data?: any, options?: ToastOptions) => {
      if (data) {
        switch (method.name) {
          case "error":
            console.error(data);
            break;
          default:
            console.info(data);
        }
      }
      return method(msg, options);
    };

  const notification = useMemo(
    () => ({
      toast: {
        success: createHandler(notify.success),
        error: createHandler(notify.error),
        loading: createHandler(notify.loading),
        custom: createHandler(notify.custom),
        promise: notify.promise,
      },
    }),
    [],
  );

  return notification;
};
