"use client";

import { ROLES } from "@ea/shared_types/next/ea.enums";
import { Route } from "next";
import { z } from "zod";
import { createRoute } from "./utils/routesCreator";

const scriptQueryParams = z
  .object({
    linked: z
      .array(
        z.object({
          script: z.coerce.number(),
          step: z.coerce.number(),
          lineNum: z.coerce.number(),
        }),
      )
      .optional(),
  })
  .optional();
/**
 * Defines the application's route configurations with strong typing for route paths.
 * This allows for a consistent and type-safe way to refer to routes throughout the application.
 * Each route is created using the `createRoute` function, ensuring that routes can be
 * programmatically constructed and validated.
 *
 * Routes.route() - returns the route path
 * Routes.route(params) - returns the route path with parameters
 * Routes.route.useParams() - returns the route parameters
 * Routes.route.useSearchParams() - returns the route query parameters
 * Routes.route.params - returns the route parameters type
 *
 * @type {Object}
 */
export const ROUTES = {
  dashboard: { route: createRoute("/(locale)") },
  schedulers: {
    route: createRoute("/(locale)/scheduler"),
    scheduler: {
      route: createRoute(
        {
          get: (params) => `/(locale)/scheduler/${params.id}`,
          params: z.object({ id: z.coerce.number() }),
        },
        scriptQueryParams,
      ),
      details: createRoute({
        get: (params) => `/(locale)/scheduler/${params.id}/details`,
        params: z.object({ id: z.coerce.number() }),
      }),
      flowChart: createRoute({
        get: (params) => `/(locale)/scheduler/${params.id}/flow-chart`,
        params: z.object({ id: z.coerce.number() }),
      }),
      history: {
        route: createRoute({
          get: (params) => `/(locale)/scheduler/${params.id}/history`,
          params: z.object({ id: z.coerce.number() }),
        }),
        jobExecutionHistory: createRoute({
          get: (params) => `/(locale)/scheduler/${params.id}/history/${params.jobExecutionId}`,
          params: z.object({
            id: z.coerce.number(),
            jobExecutionId: z.string(),
          }),
        }),
      },
    },
    calendar: { route: createRoute("/(locale)/scheduler/schedulers-calendar") },
    table: { route: createRoute("/(locale)/scheduler/schedulers-table") },
  },
  scripts: {
    route: createRoute("/(locale)/scripts"),
    script: {
      route: createRoute(
        {
          get: (params) => `/(locale)/scripts/${params.id}`,
          params: z.object({ id: z.coerce.number() }),
        },
        scriptQueryParams,
      ),
      steps: {
        route: createRoute(
          {
            get: (params) => `/(locale)/scripts/${params.id}/steps`,
            params: z.object({ id: z.coerce.number() }),
          },
          scriptQueryParams,
        ),
      },
      details: {
        route: createRoute(
          {
            get: (params) => `/(locale)/scripts/${params.id}/details`,
            params: z.object({ id: z.coerce.number() }),
          },
          scriptQueryParams,
        ),
      },
      variables: {
        route: createRoute(
          {
            get: (params) => `/(locale)/scripts/${params.id}/variables`,
            params: z.object({ id: z.coerce.number() }),
          },
          scriptQueryParams,
        ),
      },
      linkedIn: {
        route: createRoute(
          {
            get: (params) => `/(locale)/scripts/${params.id}/linked-in`,
            params: z.object({ id: z.coerce.number() }),
          },
          scriptQueryParams,
        ),
      },
      advancedConditionals: {
        route: createRoute(
          {
            get: (params) => `/(locale)/scripts/${params.id}/advanced-conditionals`,
            params: z.object({ id: z.coerce.number() }),
          },
          scriptQueryParams,
        ),
      },
      issueTracking: {
        route: createRoute(
          {
            get: (params) => `/(locale)/scripts/${params.id}/issue-tracking`,
            params: z.object({ id: z.coerce.number() }),
          },
          scriptQueryParams,
        ),
      },
      documentation: {
        route: createRoute(
          {
            get: (params) => `/(locale)/scripts/${params.id}/documentation`,
            params: z.object({ id: z.coerce.number() }),
          },
          scriptQueryParams,
        ),
      },
      history: {
        route: createRoute(
          {
            get: (params) => `/(locale)/scripts/${params.id}/history`,
            params: z.object({ id: z.coerce.number() }),
          },
          scriptQueryParams,
        ),
        sessionHistory: createRoute({
          get: (params) => `/(locale)/scripts/${params.id}/history/${params.sessionId}`,
          params: z.object({
            id: z.coerce.number(),
            sessionId: z.string(),
          }),
        }),
        stepsHistory: createRoute({
          get: (params) =>
            `/(locale)/scripts/${params.id}/history/${params.sessionId}/${params.executionId}`,
          params: z.object({
            id: z.coerce.number(),
            executionId: z.number(),
            sessionId: z.string(),
          }),
        }),
      },
      changeLog: {
        route: createRoute(
          {
            get: (params) => `/(locale)/scripts/${params.id}/change-log`,
            params: z.object({ id: z.coerce.number() }),
          },
          scriptQueryParams,
        ),
        stepsChangeLog: createRoute({
          get: (params) => `/(locale)/scripts/${params.id}/change-log/${params.changeLogId}`,
          params: z.object({
            id: z.coerce.number(),
            changeLogId: z.coerce.number(),
          }),
        }),
      },
    },
  },
  history: {
    route: createRoute("/(locale)/history"),
  },
  settings: {
    userAndGroups: {
      users: {
        route: createRoute("/(locale)/settings/users-and-group/users"),
      },
      groups: {
        route: createRoute("/(locale)/settings/users-and-group/groups"),
      },
      configuration: {
        route: createRoute("/(locale)/settings/users-and-group/configuration"),
      },
    },
    scriptTranslation: {
      route: createRoute("/(locale)/settings/scripts-translation"),
    },
    globalSettings: {
      route: createRoute("/(locale)/settings/global-settings"),
    },
    apiKey: {
      route: createRoute("/(locale)/settings/api-key"),
    },
    systemDefinitions: {
      route: createRoute("/(locale)/settings/system-definitions"),
    },
    virtualUsers: {
      route: createRoute("/(locale)/settings/virtual-users"),
      pool: createRoute("/(locale)/settings/virtual-users/pool"),
    },
    runner: {
      route: createRoute("/(locale)/settings/runner-settings"),
    },
    documentsTemplates: {
      route: createRoute("/(locale)/settings/documents-templates"),
    },
    integrations: {
      trackers: {
        route: createRoute("/(locale)/settings/integrations/trackers"),
      },
      rules: {
        route: createRoute("/(locale)/settings/integrations/rules"),
      },
      configurations: {
        route: createRoute("/(locale)/settings/integrations/configurations"),
      },
    },
  },
  projects: {
    route: createRoute("/(locale)/projects"),
    project: {
      route: createRoute({
        get: (params) => `/(locale)/projects/${params.id}`,
        params: z.object({ id: z.coerce.number() }),
      }),
      scripts: {
        route: createRoute({
          get: (params) => `/(locale)/projects/${params.id}/scripts`,
          params: z.object({ id: z.coerce.number() }),
        }),
      },
      history: {
        route: createRoute({
          get: (params) => `/(locale)/projects/${params.id}/history`,
          params: z.object({ id: z.coerce.number() }),
        }),
        jobExecutionHistory: createRoute({
          get: (params) => `/(locale)/projects/${params.id}/history/${params.jobExecutionId}`,
          params: z.object({
            id: z.coerce.number(),
            jobExecutionId: z.string(),
          }),
        }),
      },
      documentation: {
        route: createRoute({
          get: (params) => `/(locale)/projects/${params.id}/documentation`,
          params: z.object({ id: z.coerce.number() }),
        }),
      },
      systems: {
        route: createRoute({
          get: (params) => `/(locale)/projects/${params.id}/systems`,
          params: z.object({ id: z.coerce.number() }),
        }),
      },
      virtualUsers: {
        route: createRoute({
          get: (params) => `/(locale)/projects/${params.id}/virtual-users`,
          params: z.object({ id: z.coerce.number() }),
        }),
      },
      userGroups: {
        route: createRoute({
          get: (params) => `/(locale)/projects/${params.id}/user-groups`,
          params: z.object({ id: z.coerce.number() }),
        }),
      },
      globalExcels: {
        route: createRoute({
          get: (params) => `/(locale)/projects/${params.id}/global-excels`,
          params: z.object({ id: z.coerce.number() }),
        }),
      },
      integrationRules: {
        route: createRoute({
          get: (params) => `/(locale)/projects/${params.id}/integration-rules`,
          params: z.object({ id: z.coerce.number() }),
        }),
      },
    },
  },
  utilities: {
    route: createRoute("/(locale)/utilities"),
    kpi: { route: createRoute("/(locale)/utilities/kpi") },
    globalVariables: { route: createRoute("/(locale)/utilities/global-variables") },
    sequences: { route: createRoute("/(locale)/utilities/sequences") },
    codeTemplates: { route: createRoute("/(locale)/utilities/code-templates") },
    codeTemplateGroups: { route: createRoute("/(locale)/utilities/code-template-groups") },
    globalExcels: { route: createRoute("/(locale)/utilities/global-excels") },
  },
  reports: {
    route: createRoute("/(locale)/reports"),
  },
  account: {
    changePassword: { route: createRoute("/(locale)/account/change-password") },
    profile: { route: createRoute("/(locale)/account/profile") },
    license: {
      route: createRoute("/(locale)/account/license"),
    },
  },
  runner: {
    route: createRoute("/(locale)/runner"),
  },
  auth: {
    login: {
      route: createRoute("/(locale)/auth/login"),
    },
    logout: {
      route: createRoute("/(locale)/auth/logout" as Route),
    },
  },
  route: createRoute("/" as Route), // special routes that throw nextjs compile error but we know that they are valid
};

/**
 * Routes groups used in permissions control
 * @type {Object}
 */
export const ROUTES_GROUPS = {
  account: "/account/*",
  scripts: "/scripts/*",
  history: "/history/*",
  scheduler: "/scheduler/*",
  settings: "/settings/*",
  projects: "/projects/*",
  reports: "/reports/*",
  documentation_tree: "/documentation_tree/*",
  documentation: "/documentation/*",
  runner: "/runner/*",
} as const satisfies Record<string, string>;

/**
 * Routes that allowed or disallowed selected roles to enter selected route
 */
export const allowedRoleRoutes = {
  [ROUTES_GROUPS.history]: {
    allowedRoles: [ROLES.logs],
    disallowedRoles: [ROLES.documentationReader],
  },
  [ROUTES_GROUPS.account]: {
    allowedRoles: [],
    disallowedRoles: [],
  },
  [ROUTES_GROUPS.scheduler]: {
    allowedRoles: [ROLES.scheduler],
    disallowedRoles: [ROLES.documentationReader],
  },
  [ROUTES_GROUPS.settings]: {
    allowedRoles: [ROLES.settings],
    disallowedRoles: [ROLES.documentationReader],
  },
  [ROUTES_GROUPS.projects]: {
    allowedRoles: [ROLES.projects],
    disallowedRoles: [],
  },
  [ROUTES_GROUPS.reports]: {
    allowedRoles: [ROLES.reports],
    disallowedRoles: [],
  },
  [ROUTES_GROUPS.runner]: {
    allowedRoles: [],
    disallowedRoles: [],
  },
  [ROUTES_GROUPS.scripts]: {
    allowedRoles: [],
    disallowedRoles: [],
  },
};
